import React from "react";
import {
  Divider,
  Stack,
  Tabs,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function RoleLayout() {
  const { pathname } = useLocation();
  const currentLink = pathname.split("/").pop();
  return (
    <React.Fragment>
      <Stack
        direction={"row"}
        spacing={1}
        divider={<Divider flexItem orientation="vertical" />}
        mb={2}
      >
        <Typography>Other Navigation Options: </Typography>
        {["home", "data", "form", "link", "badge", "email", "response"].map(
          (item) => (
            <MuiLink
              component={item === currentLink ? "button" : Link}
              to={item === "home" ? "../" : item}
              underline="hover"
              textTransform="capitalize"
            >
              {item}
            </MuiLink>
          )
        )}
      </Stack>
      <Outlet />
    </React.Fragment>
  );
}
