import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { AddCircle, Delete, Edit } from "@mui/icons-material";

export default function RoleLinkPage() {
  const { eventId, role } = useParams();
  const [add, setAdd] = React.useState(false);

  const { data, error, refetch } = useQuery(GET_LINK, {
    variables: { eventId, role },
  });
  const [updateLink] = useMutation(UPDATE_LINK);
  const [createLink] = useMutation(CREATE_LINK);
  const [deleteLink] = useMutation(DELETE_LINK);

  const onUpdate = async (f) => {
    return updateLink({
      variables: {
        inp: {
          eventId,
          role,
          ...f,
        },
      },
    })
      .then((res) => refetch())
      .then(() => true)
      .catch((e) => alert(JSON.stringify(e, null, 2)));
  };

  const onCreate = async (f) => {
    return createLink({
      variables: {
        inp: {
          eventId,
          role,
          ...f,
        },
      },
    })
      .then((res) => refetch())
      .then(() => true)
      .catch((e) => alert(JSON.stringify(e, null, 2)));
  };
  const onDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      deleteLink({ variables: { id } }).then(() => refetch());
    }
  };

  return (
    <Box>
      <AddLinkDialog
        open={add}
        onClose={() => setAdd(false)}
        onSubmit={async (f) => {
          if (await onCreate(f)) {
            setAdd(false);
          }
        }}
      />

      <Typography
        variant="h4"
        gutterBottom
        sx={{ textTransform: "capitalize" }}
      >
        {role} Registration Links
      </Typography>
      {!!error && (
        <Typography color="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Typography>
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>#</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Form Heading</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          <TableBody>
            {data?.links?.map((l, i) => (
              <LinkRow
                link={l}
                key={i}
                i={i + 1}
                onUpdate={onUpdate}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="success"
        sx={{ mt: 4 }}
        startIcon={<AddCircle />}
        onClick={() => setAdd(true)}
      >
        Add New Link
      </Button>

      <Box p={4} />
    </Box>
  );
}

function LinkRow({ link, i, onUpdate, onDelete }) {
  const [edit, setEdit] = React.useState(false);
  return (
    <React.Fragment>
      <EditLinkDialog
        link={link}
        open={edit}
        onClose={() => setEdit(false)}
        onSubmit={async (f) => {
          if (await onUpdate(f)) {
            setEdit(false);
          }
        }}
      />

      <TableRow>
        <TableCell>{i}</TableCell>
        <TableCell>
          <Link href={`https://eventegration.com/${link.link}`}>
            https://eventegration.com/{link.link}
          </Link>
        </TableCell>
        <TableCell>{link.formHeading}</TableCell>
        <TableCell>{link.active ? "Yes" : "No"}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => setEdit(true)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => onDelete(link.id)}>
              <Delete />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EditLinkDialog({ open, onClose, link, onSubmit }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Link</DialogTitle>
      <DialogContent>
        <Box p={1}>
          {!!link && <LinkSetup link={link} onSubmit={onSubmit} />}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
function AddLinkDialog({ open, onClose, onSubmit }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Link</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <LinkSetup onSubmit={onSubmit} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function LinkSetup({ onSubmit, link }) {
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      link: "",
      formHeading:"",
      active: false,
    },
  });
  React.useEffect(() => {
    if (link?.link) {
      const { __typename, ...formData } = link;
      reset(formData);
    }
  }, [link]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="link"
          rules={{ required: "Required", maxLength: 500 }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              inputRef={ref}
              {...field}
              required
              error={!!error}
              helperText={error?.message}
              fullWidth
              label="Link"
            />
          )}
        />
        <Controller
          control={control}
          name="formHeading"
          rules={{ required: "Required", maxLength: 500 }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              inputRef={ref}
              {...field}
              required
              error={!!error}
              helperText={error?.message}
              fullWidth
              label="Form Heading"
            />
          )}
        />
        <Controller
          control={control}
          name="active"
          render={({ field }) => (
            <FormControlLabel
              control={
                <Switch checked={field.value} onChange={field.onChange} />
              }
              label="Active"
            />
          )}
        />

        <Stack direction="row" spacing={2}>
          <LoadingButton
            loading={formState.isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
          {!!link?.link && (
            <Button variant="contained" color="error" onClick={() => reset()}>
              Delete
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  );
}

const GET_LINK = gql`
  query ($eventId: String!, $role: String!) {
    links(eventId: $eventId, role: $role) {
      id
      link
      active
      formHeading
    }
  }
`;

const UPDATE_LINK = gql`
  mutation ($inp: InputEventLink!) {
    updateLink(inp: $inp) {
      id
    }
  }
`;
const CREATE_LINK = gql`
  mutation ($inp: InputEventLink!) {
    createLink(inp: $inp) {
      id
    }
  }
`;
const DELETE_LINK = gql`
  mutation ($id: Int!) {
    deleteLink(id: $id)
  }
`;
