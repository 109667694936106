import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import {
  Backspace,
  BadgeTwoTone,
  Delete,
  DeleteTwoTone,
  SendRounded,
} from "@mui/icons-material";
import SendEmail from "../Components/SendEmail";
import CertificateIcon from "@mui/icons-material/WorkspacePremium";

export default function RoleDataPage() {
  const { eventId, role } = useParams();
  const [search, setSearch] = React.useState("");

  return (
    <Stack spacing={2}>
      <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
        {role} Data
      </Typography>

      <SearchBox onSearch={setSearch} />
      <DataTable eventId={eventId} role={role} search={search} />
    </Stack>
  );
}

function DataTable({ eventId, role, search }) {
  const limit = 100;

  const { data, loading, error, refetch } = useQuery(GET_DATA, {
    variables: { eventId, role, conditions: { limit, text: search } },
    onError: (e) => console.log(JSON.stringify(e, null, 2)),
  });
  const [doDelete] = useMutation(DELETE_APPLICANT, { onCompleted: refetch });
  const [updateField] = useMutation(UPDATE_APPLICANT_FIELD, {
    onCompleted: refetch,
  });
  const [selectedIds, setSelectedIds] = React.useState([]);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }
  return (
    <Stack spacing={2}>
      <Box>
        <DataGrid
          paginationMode="server"
          pageSize={limit}
          rowsPerPageOptions={[limit]}
          onPageChange={(p) =>
            refetch({
              conditions: {
                offset: p * limit,
                limit,
              },
            })
          }
          rowCount={data?.total || 0}
          sx={{ height: 600 }}
          // experimentalFeatures={{ newEditingApi: true }}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            setSelectedIds(
              ids.map((id) => {
                const applicant = JSON.parse(
                  data?.applicants?.find((d) => d.id === id)?.data ||
                    "{email:'undefined'}"
                );
                return {
                  id,
                  email: applicant.email || applicant.Email,
                };
              })
            );
          }}
          onCellEditCommit={(p, e) =>
            e.key === "Enter" &&
            // && console.log(p)
            updateField({
              variables: {
                eventId,
                role,
                id: p.id,
                field: p.field,
                value: p.value,
              },
            })
              // .then((r) => window.alert(r.data.result))
              .catch((e) => window.alert(e.message))
          }
          components={{
            Toolbar: GridToolbar,
          }}
          columns={[
            {
              type: "actions",
              field: "actions",
              headerName: "Actions",
              width: 150,
              getActions: (p) => [
                <GridActionsCellItem
                  label="Delete"
                  onClick={() => {
                    if (
                      !window.confirm("Are you sure you want to delete this?")
                    ) {
                      return;
                    }
                    doDelete({ variables: { eventId, role, id: p.id } })
                      .catch((e) => window.alert(e.message))
                      .then((r) => window.alert(r.data.result));
                  }}
                  icon={<DeleteTwoTone color="error" />}
                />,
                <Divider flexItem orientation="vertical" />,
                <GridActionsCellItem
                  label="Badge"
                  onClick={() =>
                    window.open(
                      `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${p.id}`
                    )
                  }
                  icon={<BadgeTwoTone color="success" />}
                />,
                <Divider flexItem orientation="vertical" />,
                <GridActionsCellItem
                  label="Badge"
                  onClick={() =>
                    window.open(
                      `https://api-reg.eventegration.com/graphql/certificate/${eventId}/${role}/${p.id}`
                    )
                  }
                  icon={<CertificateIcon color="primary" />}
                />,
              ],
            },
            { field: "id", width: 110 },

            {
              field: "created",
              width: 200,
              valueFormatter: (row) =>
                format(+row.value, "dd-MMM-yyyy hh:mm a"),
            },
            { field: "regLink", width: 300, headerName: "Registration Link" },

            ...data?.form.fields.map((f) => ({
              field: f.name,
              width: f.name === "permissions" ? 600 : 200,
              editable: true,
            })),
          ]}
          rows={data?.applicants.map((row) => ({
            ...row,
            ...JSON.parse(row.data),
          }))}
        />
      </Box>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
        <SendEmail {...{ selectedIds, eventId, role }} />
      </Stack>
      <Box p={4} />
    </Stack>
  );
}

function SearchBox({ onSearch }) {
  const [search, setSearch] = React.useState("");
  React.useEffect(() => {
    const t = setTimeout(() => onSearch(search), 500);
    return () => clearTimeout(t);
  }, [search]);
  return (
    <TextField
      sx={{ maxWidth: 500 }}
      label="Search here..."
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={{
        autoComplete: "new-password",
        endAdornment: (
          <InputAdornment position="end">
            {search && (
              <IconButton onClick={() => setSearch("")}>
                <Backspace />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

const GET_DATA = gql`
  query ($eventId: String!, $role: ROLE!, $conditions: InputConditions) {
    applicants(eventId: $eventId, role: $role, conditions: $conditions) {
      serial
      id
      created
      regLink
      data
    }
    total: applicantsCount(
      eventId: $eventId
      role: $role
      conditions: $conditions
    )
    form(eventId: $eventId, role: $role) {
      fields {
        name
      }
    }
  }
`;

const DELETE_APPLICANT = gql`
  mutation ($eventId: String!, $role: ROLE!, $id: String!) {
    result: deleteApplicant(eventId: $eventId, role: $role, id: $id)
  }
`;
const UPDATE_APPLICANT_FIELD = gql`
  mutation (
    $eventId: String!
    $role: ROLE!
    $id: String!
    $field: String!
    $value: String!
  ) {
    result: updateApplicantField(
      eventId: $eventId
      role: $role
      id: $id
      field: $field
      value: $value
    )
  }
`;
