import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  Button,
  TextField,
  Stack,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { API_URL, downloadFile, IMAGES_API_URL } from "../constants";
import { useMutation, gql, useQuery } from "@apollo/client";
import { PDFDocument, StandardFonts, rgb, degrees } from "pdf-lib";
import { QRCodeCanvas } from "@cheprasov/qrcode";
import { fetch } from "cross-fetch";

export default function RoleBadgePage() {
  const { eventId, role } = useParams();
  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textTransform: "capitalize" }}
      >
        {role} Badge
      </Typography>

      <Container maxWidth="xl">
        <BadgeBuilder eventId={eventId} role={role} />
      </Container>
      <Box p={4} />
    </Box>
  );
}

function BadgeBuilder({ eventId, role }) {
  const [doc, setDoc] = React.useState(null);

  const { data: badgeData, refetch } = useQuery(
    gql`
      query ($eventId: String!, $role: ROLE!) {
        badge(eventId: $eventId, role: $role)
      }
    `,
    { variables: { eventId, role }, onCompleted: (e) => console.log(e) }
  );

  const [upload, { loading, error }] = useMutation(gql`
    mutation ($file: Upload!, $eventId: String!, $role: ROLE!) {
      uploadEventRoleBadge(eventId: $eventId, role: $role, file: $file)
    }
  `);

  const [deleteBadge] = useMutation(gql`
    mutation ($eventId: String!, $role: ROLE!) {
      deleteEventRoleBadge(eventId: $eventId, role: $role)
    }
  `);

  const [saveBadge] = useMutation(gql`
    mutation ($eventId: String!, $role: ROLE!, $badge: String!) {
      saveBadge(eventId: $eventId, role: $role, badge: $badge)
    }
  `);

  function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid)
      upload({ variables: { eventId, role, file } }).then(onLoadDoc);
  }

  const onLoadDoc = async () => {
    try {
      const url = API_URL + `download-badge/${eventId}/${role}`;
      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );
      setDoc(await PDFDocument.load(existingPdfBytes));
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveElements = (badge) => {
    // console.log(badge);
    return saveBadge({
      variables: {
        eventId,
        role,
        badge,
      },
    })
      .then((res) => alert(res.data.saveBadge))
      .catch((e) => alert(JSON.stringify(e, null, 2)));
  };

  const handleExportDesign = async (design) => {
    // console.log(design);
    downloadFile(
      document,
      JSON.stringify(design),
      `${eventId}_${role}_design.json`
    );
  };

  const handleImportDesign = async (e) => {
    const [file] = e.target.files;
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      handleSaveElements(reader.result).then(() => refetch());
    };
    reader.readAsText(file);
  };

  React.useEffect(() => {
    const t = setTimeout(onLoadDoc, 1000);
    return () => {
      clearTimeout(t);
    };
  }, [eventId, role]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <label htmlFor="pdf">Select Badge(PDF) to Import: </label>
          <input
            type="file"
            accept="application/pdf"
            id="pdf"
            name="pdf"
            required
            onChange={onChange}
          />
          <label htmlFor="design">Select Design(JSON) to Import: </label>
          <input
            type="file"
            accept="application/json"
            id="design"
            name="design"
            required
            onChange={handleImportDesign}
          />
          <Box>
            <Button
              onClick={() => deleteBadge({ variables: { eventId, role } })}
              color="error"
            >
              Remove PDF
            </Button>
            <Button
              onClick={() => handleSaveElements("[]").then(() => refetch())}
              color="error"
            >
              Remove Design
            </Button>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {!doc ? (
          "Upload Badge PDF"
        ) : (
          <RoleBadge
            doc={doc}
            onSave={handleSaveElements}
            onExportDesign={handleExportDesign}
            badge={badgeData?.badge}
          />
        )}
      </Grid>
    </Grid>
  );
}

function RoleBadge({ doc, onSave, badge, onExportDesign }) {
  const [elements, setElements] = React.useState(
    !badge ? [] : JSON.parse(badge)
  );
  const pdfRef = React.useRef(null);
  React.useEffect(() => {
    (async () => {
      if (doc) {
        pdfRef.current.src =
          (await modifyPdf(doc, elements)) + "#page=1&zoom=100";
      }
    })();
  }, [elements]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <Stack spacing={1}>
          {elements.map((el, i) => (
            <TextField
              key={i}
              defaultValue={el}
              label="Element Config"
              fullWidth
              multiline
              maxRows={30}
              onKeyDown={(e) => {
                if (e.ctrlKey && e.key === "Enter") {
                  const v = e.target.value;
                  if (!v) {
                    console.log("removing", i);
                    setElements(elements.filter((_, k) => i !== k));
                  } else {
                    setElements(
                      elements.map((a, k) => {
                        return JSON.parse(a).id === JSON.parse(v).id ? v : a;
                      })
                    );
                  }
                }
              }}
            />
          ))}
          <Button
            fullWidth
            size="large"
            color="success"
            variant="contained"
            onClick={() =>
              setElements([
                ...elements,
                JSON.stringify(
                  {
                    id: Date.now(),
                    type: "text",
                    value: "$First Name",
                    bold: false,
                    size: 16,
                    bound: {
                      x: 10,
                      y: 600,
                      width: 100,
                      height: 16,
                    },
                  },
                  null,
                  2
                ),
              ])
            }
          >
            Add More Element
          </Button>
          <Button
            onClick={() => onSave(JSON.stringify(elements))}
            color="error"
            fullWidth
            variant="contained"
            size="large"
          >
            Save Badge
          </Button>
          <Button
            onClick={() => onExportDesign(elements)}
            color="warning"
            fullWidth
            variant="contained"
            size="large"
          >
            Export Design
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8} container spacing={1}>
        <Grid item xs={12}>
          {/* <iframe ref={pdfRef} style={{ width: "100%", height: 400 }}></iframe>; */}
          <embed
            ref={pdfRef}
            width="100%"
            height={842}
            type="application/pdf"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

async function modifyPdf(docOriginal, elements) {
  const doc = await docOriginal.copy();
  const font = await doc.embedFont(StandardFonts.Helvetica);
  const fontBold = await doc.embedFont(StandardFonts.HelveticaBold);

  const pages = doc.getPages();
  const page = pages[0];
  const { width, height } = page.getSize();
  page.resetPosition();

  const drawText = (
    text,
    {
      size = 16,
      bold = false,
      color = 'black',
      bounds = { x: 100, y: 100, width: 100, height: 20 },
    } = {}
  ) => {
    let textWidth = (bold ? fontBold : font).widthOfTextAtSize(text, size);
    if (textWidth > bounds.width) {
      size *= 0.8;
      textWidth = (bold ? fontBold : font).widthOfTextAtSize(text, size);
    }

    page.moveTo(
      bounds.x + (bounds.width - textWidth) / 2,
      bounds.y + bounds.height - size
    );

    page.drawText(text, {
      size,
      font: bold ? fontBold : font,
      color: rgb(...parseColor(color)), // Set the text color
    });
  };

  const parseColor = (colorString) => {
    // Implement a function to parse color string to RGB values
    // For simplicity, this example assumes the colorString is a basic color name like 'red', 'green', 'blue'
    // You can extend this function to support different formats (like hex codes) as needed
    const colors = {
      red: [1, 0, 0],
      green: [0, 1, 0],
      blue: [0, 0, 1],
      black: [0, 0, 0],
      white: [1, 1, 1], // Added white color
      // Add more colors as needed
    };
    
    return colors[colorString] || colors.black; // Default to black if color is not found
  };

  if (elements?.length) {
    for (const str of elements) {
      const el = JSON.parse(str);
      // page.drawRectangle({
      //   ...el.bound,
      //   borderColor: rgb(1, 0, 0),
      //   borderWidth: 1,
      // });
      if (el.type === "text") {
        if (
          font.widthOfTextAtSize(el.value, 18) > el.bound.width &&
          el.value.indexOf("||") > -1
        ) {
          drawText(el.value.split("||")[0].trim(), {
            bold: el.bold,
            bounds: el.bound,
            size: el.size,
            color:el.color,
          });
          drawText(el.value.split("||")[1].trim(), {
            bold: el.bold,
            bounds: { ...el.bound, y: el.bound.y - el.size - el.size * 0.25 },
            size: el.size,
            color:el.color,
          });
        } else if (font.widthOfTextAtSize(el.value, 18) > el.bound.width) {
          drawText(el.value.replace("||", " "), {
            bold: el.bold,
            size: Math.floor(el.size * 0.7),
            bounds: el.bound,
          });
        } else {
          drawText(el.value.replace("||", " "), {
            bold: el.bold,
            size: el.size,
            color:el.color, // Math.floor(el.size * 0.7),
            bounds: el.bound,
          });
        }
      } else if (el.type === "qr") {
        const qr = new QRCodeCanvas(el.value);
        const qrImg = await doc.embedPng(qr.toDataUrl());
        const qrSize = el.size;
        page.drawImage(qrImg, {
          x: el.bound.x + (el.bound.width - qrSize) / 2,
          y: el.bound.y,
          width: qrSize,
          height: qrSize,
        });
      }
    }
  }
  // console.log("should display pdf");

  const bytes = await doc.save();
  const blob = new Blob([bytes], { type: "application/pdf" });

  return URL.createObjectURL(blob);
}
