import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

export default function MyEventsPage() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        My Events
      </Typography>

      <EventsTable />
    </div>
  );
}

function EventsTable() {
  const { data, loading, error, refetch } = useQuery(MY_EVENTS);
  const navigate = useNavigate();
  const [deleteEvent, setDeleteEvent] = React.useState(null);
  const [doDelete] = useMutation(
    gql`
      mutation ($eventId: String!) {
        deleteEvent(eventId: $eventId)
      }
    `
  );

  const handleDeleteEvent = (ev) => {
    setDeleteEvent(ev);
  };
  const handleDeleteEventConfirmed = async () => {
    doDelete({ variables: { eventId: deleteEvent.id } })
      .then(() => refetch())
      .then(() => setDeleteEvent(null));
  };

  React.useEffect(() => {
    refetch();
  }, []);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box py={2}>
      <AskToDelete
        event={deleteEvent}
        onConfirm={handleDeleteEventConfirmed}
        open={!!deleteEvent}
        onClose={() => setDeleteEvent(null)}
      />
      <TableContainer sx={{ pb: 2 }}>
        <Table>
          <TableHead>
            <TableCell>#</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          <TableBody>
            {data?.events?.map((event, i) => (
              <TableRow key={event.id}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{event.id}</TableCell>
                <TableCell>{event.name}</TableCell>
                <TableCell>{event.roles}</TableCell>
                <TableCell>
                  {format(+event.created, "d MMM, yyyy h:m a")}
                </TableCell>
                <TableCell>
                  <Button onClick={() => navigate(event.id)}>Open</Button>
                  <Button
                    onClick={() => handleDeleteEvent(event)}
                    color="error"
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!error && <Typography color="error">{error.message}</Typography>}
      <Stack direction="row" spacing={1}>
        <Button onClick={() => refetch()} variant="contained">
          Refresh
        </Button>
        <Button
          color="success"
          onClick={() => navigate("new")}
          variant="contained"
        >
          Create New
        </Button>
      </Stack>
    </Box>
  );
}

function AskToDelete({ onConfirm, event, open, onClose }) {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const keey =
    event?.name.substr(0, 20).trim().toLowerCase().replaceAll(" ", "-") ||
    "...";

  return (
    <Dialog open={open} onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onConfirm)}>
        <Stack
          p={2}
          spacing={2}
          sx={{ minWidth: (t) => t.breakpoints.values.sm }}
        >
          <Typography color="error" fontWeight="bold">
            {keey}
          </Typography>
          <Typography gutterBottom>
            Write above captcha in box to confirm delete.
          </Typography>
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
              pattern: new RegExp("^" + keey + "$"),
            }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                label="captcha"
                InputLabelProps={{ shrink: true }}
                error={!!error}
                helperText={error?.message}
                fullWidth
                autoComplete="new-password"
                autoFocus
              />
            )}
          />
          <LoadingButton
            sx={{ my: 2 }}
            loading={formState.isSubmitting}
            variant="contained"
            color="error"
            fullWidth
            type="submit"
          >
            DELETE EVENT
          </LoadingButton>
          <Button fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}

const MY_EVENTS = gql`
  query {
    events {
      id
      name
      created
      roles
    }
  }
`;
