import React from "react";
import { useNavigate } from "react-router-dom";

export default function LogoutPage() {
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.clear();
    navigate("/", { replace: true });
  }, []);

  return <div>Please wait...</div>;
}
