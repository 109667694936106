import { gql, useQuery, useMutation } from "@apollo/client";
import {
  AddCircle,
  DeleteForever,
  FileDownload,
  FileUpload,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import TrashBin from "../Assets/trash-bin.png";
import { downloadFile } from "../constants";
import { GET_FORM_FIELDS } from "./EventStandsPage";
import FeaturePanel from "../Components/FeaturePanel";

// function VerificationPanel({ eventId, role }) {

// }

// function IsVerificationFeatureRequired({ eventId, role }) {
  // const [tags, setTags] = React.useState([]);
  // const [keywords, setKeywords] = React.useState([]);
  // const [action, setAction] = React.useState([]);
  // const [socialShare, setSocialShare] = React.useState([]);
  // const [keywordApplyColum, setKeywordApplyColum] = React.useState([]);
  // const [addMailVerificationFeature] = useMutation(ADD_FEATURE);
  // // const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
  // //   variables: { eventId, role },
  // // });


  // React.useEffect(() => {
  //   if (featureData && featureData.mailVerificationFeature) {
  //     let parsedData = [];
  //     let parsedKeywords = [];
  //     let parsedAction = [];
  //     let parsedkeywordsAppliedColumns = [];
  //     let parsedSocialShare = [];
  //     try {
  //       parsedData = JSON.parse(featureData.mailVerificationFeature.features);
  //       parsedKeywords = JSON.parse(featureData.mailVerificationFeature.keywords);
  //       parsedAction = JSON.parse(featureData.mailVerificationFeature.action);
  //       parsedkeywordsAppliedColumns= JSON.parse(featureData.mailVerificationFeature.keywordsAppliedColumns);
  //       parsedSocialShare= JSON.parse(featureData.mailVerificationFeature.socialShare);
  //     } catch (err) {
  //       console.error("Error parsing the data:", err);
  //     }
  //     if (Array.isArray(parsedData)) {
  //       setTags(parsedData);
  //       setKeywords(parsedKeywords);
  //       setAction(parsedAction);
  //       setKeywordApplyColum(parsedkeywordsAppliedColumns);
  //       setSocialShare(parsedSocialShare);
  //     }
  //   }
  // }, [featureData]);

  // const features = ["emailVerification", "WhatsappRegistration","ShareButton","DownloadBadge","paypal","stripe","checkout","razorpay","paytm"];
  // const actions = ["accept", "reject"];
  // const availableActions = action.includes('accept') ? ['reject'] : action.includes('reject') ? ['accept'] : actions;

  // const { data: columnData, loading: columnLoading } = useQuery(GET_FORM_FIELDS, {
  //   variables: { eventId },
  // });
 
  // const allColumnList = columnData?.getAllFormField;


  // const handleSocialShareChange = (event, newValue) => {
  //   const field = "socialShare"
  //   setSocialShare(newValue);
  //   addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  // };
  // const handleTagsChange = (event, newValue) => {
  //   const field = "features"
  //   setTags(newValue);
  //   addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  // };
  // const handleKeywordsChange = (event, newValue) => {
  //   const field = "keywords"
  //   setKeywords(newValue);
  //   addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  // };
  // const handleActionChange = (event, newValue) => {
  //   const field = "action"
  //   setAction(newValue);
  //   addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  // };
  // const keywordsAppliedColumns = (event, newValue) => {
  //   const field = "keywordsAppliedColumns"
  //   setKeywordApplyColum(newValue);
  //   addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  // };

  // if (featureLoading) return <span>Loading...</span>;

//   return (
//     <Grid container spacing={2} m={2} sx={{ width: "100%" }}>
//       <Grid item md={6} xs={12}>
//         <Autocomplete
//           multiple
//           freeSolo
//           defaultValue={tags}
//           options={features}
//           value={tags}
//           onChange={handleTagsChange}
//           renderTags={(value, getTagProps) =>
//             Array.isArray(value) ? value.map((option, index) => (
//               <Chip
//                 variant="outlined"
//                 color="success"
//                 label={option}
//                 {...getTagProps({ index })}
//               />
//             )) : null
//           }

//           renderInput={(params) => (
//             <Stack direction="column">
//               <TextField
//                 {...params}
//                 label="Add features in this form"
//                 variant="outlined"
//                 fullWidth
//               />
//             </Stack>
//           )}
//         />
//       </Grid>
      // <Grid item md={6} xs={12}>
      //   <Autocomplete
      //     multiple
      //     freeSolo
      //     defaultValue={keywords}
      //     options={[]}
      //     value={keywords}
      //     onChange={handleKeywordsChange}
      //     renderTags={(value, getTagProps) =>
      //       Array.isArray(value) ? value.map((option, index) => (
      //         <Chip
      //           variant="outlined"
      //           color="success"
      //           label={option}
      //           {...getTagProps({ index })}
      //         />
      //       )) : null
      //     }

      //     renderInput={(params) => (
      //       <Stack direction="column">
      //         <TextField
      //           {...params}
      //           label="Add Keywords"
      //           variant="outlined"
      //           fullWidth
      //         />
      //       </Stack>
      //     )}
      //   />
      // </Grid>
      // <Grid item md={6} xs={12}>
      //   <Autocomplete
      //     multiple={false}
      //     freeSolo
      //     defaultValue={action}
      //     options={availableActions}
      //     value={action}
      //     onChange={handleActionChange}
      //     renderTags={(value, getTagProps) =>
      //       Array.isArray(value) ? value.map((option, index) => (
      //         <Chip
      //           variant="outlined"
      //           color="success"
      //           label={option}
      //           {...getTagProps({ index })}
      //         />
      //       )) : null
      //     }
      //     renderInput={(params) => (
      //       <Stack direction="column">
      //         <TextField
      //           {...params}
      //           label="If keyword match then which action you want to perform"
      //           variant="outlined"
      //           fullWidth
      //         />
      //       </Stack>
      //     )}
      //   />
      // </Grid>
      
      // <Grid item md={6} xs={12}>
      // <Autocomplete
      //   multiple
      //   freeSolo
      //   defaultValue={keywordApplyColum}
      //   options={allColumnList}
      //   value={keywordApplyColum}
      //   onChange={keywordsAppliedColumns}
      //   renderTags={(value, getTagProps) =>
      //     Array.isArray(value) ? value.map((option, index) => (
      //         <Chip
      //             variant="outlined"
      //             color="success"
      //             label={option}
      //             {...getTagProps({ index })}
      //         />
      //     )) : null
      // }
      
      //   renderInput={(params) => (
      //     <Stack direction="column">
      //       <TextField
      //         {...params}
      //         label="Select column where you want to apply keywords"
      //         variant="outlined"
      //         fullWidth
      //       />
           
      //     </Stack>
      //   )}
      // />
      // </Grid>
      // <Grid item md={12} xs={12}>
      //   <Autocomplete
      //     multiple={false}
      //     freeSolo
      //     defaultValue={socialShare}
      //     options={[]}
      //     value={socialShare}
      //     onChange={handleSocialShareChange}
      //     renderTags={(value, getTagProps) =>
      //       Array.isArray(value) ? value.map((option, index) => (
      //         <Chip
      //           variant="outlined"
      //           color="success"
      //           label={option}
      //           {...getTagProps({ index })}
      //         />
      //       )) : null
      //     }
      //     renderInput={(params) => (
      //       <Stack direction="column">
      //         <TextField
      //           {...params}
      //           label="SocialShare Content"
      //           variant="outlined"
      //           fullWidth
      //         />
      //       </Stack>
      //     )}
      //   />
      // </Grid>

//     </Grid>
//   );
// }




export default function RoleFormPage() {
  const { eventId, role } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_FORM, {
    variables: { eventId, role },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{ textTransform: "capitalize" }}
        gutterBottom
      >
        {role} Form
      </Typography>
      
      <FormBuilder
        eventId={eventId}
        role={role}
        refetch={refetch}
        fields={data.form.fields}
      />
      <Box p={2} />

      <UniqueColumns eventId={eventId} role={role} fields={data.form.fields} />

      <Box p={2} />
      <Typography gutterBottom variant="h5">
        Some Regex Patterns:
      </Typography>
      <Typography>Calling Code: ^\+\d+$</Typography>
      <Typography>Mobile: ^\d+$</Typography>
      <Box p={2} />
      <FeaturePanel eventId={eventId} role={role} />
    </Box>
  );
}

function UniqueColumns({ eventId, role, fields }) {
  const { data, loading, error, refetch } = useQuery(UNIQUE_FIELDS, {
    variables: { eventId, role },
  });

  const [updateIndividual] = useMutation(UPDATE_INDIVIDUAL_UNIQUE);
  const [updateCombined] = useMutation(UPDATE_COMBINED_UNIQUE);

  const [individualFields, setIndividualFields] = React.useState([]);
  const [combinedFields, setCombinedFields] = React.useState([]);

  const allowedFields = (f) => ["text", "email", "mobile","payment"].includes(f.type);

  const handleIndividualSubmit = async () => {
    const fieldNames = individualFields.map((a) => a.column);
    await updateIndividual({
      variables: {
        eventId,
        role,
        fieldNames,
      },
    })
      .then(() => refetch())
      .catch((e) => alert(JSON.stringify(e, null, 4)));
  };
  const handleCombinedSubmit = async () => {
    const fieldNames = combinedFields.map((a) => a.column);
    await updateCombined({
      variables: {
        eventId,
        role,
        fieldNames,
      },
    })
      .then(() => refetch())
      .catch((e) => alert(JSON.stringify(e, null, 4)));
  };

  React.useEffect(() => {
    if (data?.keys) {
      setIndividualFields(
        data?.keys?.filter((k) => k.index.startsWith("individual")) || []
      );
      setCombinedFields(
        data?.keys?.filter((k) => k.index.startsWith("combined")) || []
      );
    }
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return (
      <Typography color="error">
        {JSON.stringify(error.message, null, 2)}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Unique Fields
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="h6">Individually</Typography>
            <FormGroup>
              {fields.filter(allowedFields).map((f) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        !!individualFields.find((k) => k.column === f.name)
                      }
                      onChange={(e) =>
                        setIndividualFields(
                          e.currentTarget.checked
                            ? [
                              ...individualFields,
                              {
                                column: f.name,
                              },
                            ]
                            : individualFields.filter(
                              (a) => a.column !== f.name
                            )
                        )
                      }
                    />
                  }
                  label={f.name}
                  key={f.name}
                />
              ))}
            </FormGroup>
            <Button variant="contained" onClick={handleIndividualSubmit}>
              Submit
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper variant="outlined" sx={{ p: 2 }}>
            <Typography variant="h6">Combined</Typography>
            <FormGroup>
              {fields.filter(allowedFields).map((f) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        !!combinedFields.find((k) => k.column === f.name)
                      }
                      onChange={(e) =>
                        setCombinedFields(
                          e.currentTarget.checked
                            ? [
                              ...combinedFields,
                              {
                                column: f.name,
                              },
                            ]
                            : combinedFields.filter((a) => a.column !== f.name)
                        )
                      }
                    />
                  }
                  label={f.name}
                  key={f.name}
                />
              ))}
            </FormGroup>
            <Button variant="contained" onClick={handleCombinedSubmit}>
              Submit
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

const fieldTypes = [
  "text",
  "email",
  "confirm_email",
  "mobile",
  "mobile_code",
  "country",
  "checkbox",
  "select",
  "mcq",
  "radio",
  "number",
  "payment",
  "html"
];

function FormBuilder({ eventId, role, fields, refetch }) {
  const [open, setOpen] = React.useState(null);
  const importFileRef = React.useRef(null);
  const [importFields, { loading, error }] = useMutation(IMPORT_FIELDS);
  const [addField] = useMutation(ADD_FIELD);
  const [deleteField] = useMutation(DELETE_FIELD);

  const handleExportForm = async () => {
    downloadFile(
      document,
      JSON.stringify(fields.map(({ __typename, ...f }) => f)),
      `${role}_form.json`
    );
  };

  const handleImportForm = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const text = reader.result;
      importFields({
        variables: {
          eventId,
          role,
          fields: JSON.parse(text),
        },
      })
        .then(async () => {
          for (const field of JSON.parse(text)) {
            await addField({
              variables: {
                eventId,
                role,
                field,
              },
            });
          }
        })
        .then(() => refetch());
    };
    reader.readAsText(file);
  };

  const handleDropped = (from, to) => {
    if (from === to) {
      return;
    }
    if (to === "__trash") {
      if (
        window.confirm(
          "Are you sure?\nDelete data also for this field: " + from
        )
      ) {
        deleteField({
          variables: {
            eventId,
            role,
            fieldName: from,
          },
        }).then(() => refetch());
      }

      return;
    }
    const fromElement = fields.find((a) => a.name === from);
    const toElement = fields.find((a) => a.name === to);
    const newFields = fields.map((a) =>
      a.name === from ? toElement : a.name === to ? fromElement : a
    );
    importFields({
      variables: {
        eventId,
        role,
        fields: newFields.map(({ __typename, ...a }) => a),
      },
    }).then(() => refetch());
  };

  // if (loading) {
  //   return <LinearProgress />;
  // }
  if (error) {
    return (
      <Typography color="error">
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <input
        type="file"
        accept="application/json"
        hidden
        ref={importFileRef}
        onChange={handleImportForm}
      />
      <AddFieldModal
        open={open !== null}
        onClose={() => setOpen(null)}
        eventId={eventId}
        role={role}
        refetch={refetch}
        defaultValues={open}
      />
      <Paper sx={{ p: 2 }} variant="outlined">
        {loading && <LinearProgress />}
        <Grid container spacing={2}>
          {fields.map((field) => {
            const commonProps = {
              fullWidth: true,
              sx: field.sx,
              onDoubleClick: () => setOpen(field),
              draggable: true,
              onDragStart: (e) => {
                e.dataTransfer.setData("fieldName", field.name);
                e.dataTransfer.effectAllowed = "move";
              },
              onDragOver: (e) => {
                e.preventDefault();
              },
              onDrop: (e) =>
                handleDropped(e.dataTransfer.getData("fieldName"), field.name),
            };
            return (
              ["html"].includes(field.type) ? (
                <TextareaAutosize
                  minRows={4}
                  value={field.defaultValue || ''}
                  readOnly
                  {...commonProps}
                />
              ) :
              <Grid item xs={field.xs} md={field.md}>
                {[
                  "text",
                  "number",
                  "email",
                  "confirm_email",
                  "mobile",
                  "mobile_code",
                ].includes(field.type) ? (
                  <TextField
                    label={field.label || field.name}
                    inputProps={{ readOnly: true }}
                    required={field.required}
                    placeholder={field.placeholder}
                    fullWidth
                    {...commonProps}
                  />
                ) : ["checkbox"].includes(field.type) ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!parseInt(field.defaultValue || "0")}
                      />
                    }
                    label={field.label || field.name}
                    {...commonProps}
                  />
                ) : ["select", "country"].includes(field.type) ? (
                  <TextField
                    label={field.label || field.name}
                    inputProps={{ readOnly: true }}
                    required={field.required}
                    placeholder={field.placeholder}
                    fullWidth
                    select
                    {...commonProps}
                  >
                    <MenuItem value="">None</MenuItem>
                    {field.options?.map((op) => (
                      <MenuItem value={op} key={op}>
                        {op}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : ["mcq"].includes(field.type) ? (
                  <FormControl {...commonProps}>
                    <FormLabel component="legend">
                      {field.label || field.name}
                    </FormLabel>
                    <FormGroup name={field.name}>
                      <Grid container spacing={1}>
                        {field.options.map((op, i) => (
                          <Grid item xs={12} md={6} key={i}>
                            <FormControlLabel
                              control={
                                <Checkbox checked={op === field.defaultValue} />
                              }
                              label={op}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                ) : ["radio","payment"].includes(field.type) ? (
                  <FormControl {...commonProps}>
                    <FormLabel component="legend">
                      {field.label || field.name}
                    </FormLabel>
                    <RadioGroup name={field.name}>
                      <Grid container spacing={1}>
                        {field.options.map((op, i) => (
                          <Grid item xs={12} md={6} key={i}>
                            <FormControlLabel
                              control={
                                <Radio checked={op === field.defaultValue} />
                              }
                              label={op}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                ) : (
                  field.name
                )}
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button
              startIcon={<AddCircle />}
              variant="contained"
              color="success"
              fullWidth
              size="large"
              onClick={() => setOpen(false)}
            >
              Add Field
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              startIcon={<FileDownload />}
              variant="contained"
              color="info"
              fullWidth
              size="large"
              onClick={handleExportForm}
            >
              Export Form
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              startIcon={<FileUpload />}
              variant="contained"
              color="warning"
              fullWidth
              size="large"
              onClick={() => importFileRef?.current?.click()}
            >
              Import Form
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              startIcon={<DeleteForever />}
              variant="outlined"
              color="error"
              fullWidth
              size="large"
              sx={{ textTransform: "none", py: 2 }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDrop={(e) =>
                handleDropped(e.dataTransfer.getData("fieldName"), "__trash")
              }
            >
              Drop any field here to delete
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

const fieldDefaultValues = {
  name: "",
  type: "text",
  label: "",
  sx: "{}",
  xs: 12,
  md: 6,
  placeholder: "",
  defaultValue: "",
  options: [],
  minLength: 0,
  maxLength: 255,
  pattern: "",
  required: false,
  readonly: false,
};

function AddFieldModal({
  open,
  onClose,
  eventId,
  role,
  refetch,
  defaultValues,
}) {
  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: fieldDefaultValues,
  });
  const [op, setOp] = React.useState(false);
  const [addField, { loading, error }] = useMutation(
    defaultValues ? UPDATE_FIELD : ADD_FIELD
  );

  React.useEffect(() => {
    if (defaultValues) {
      const { __typename, ...values } = defaultValues;
      reset(values);
      setOp(["select", "mcq", "radio", "payment"].includes(values.type));
    } else {
      reset(fieldDefaultValues);
      setOp(false);
    }
  }, [defaultValues]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <form
        noValidate
        onSubmit={handleSubmit(async (field) => {
          field.options = field.options.filter((v) => !!v);

          console.log(field);

          return await addField({
            variables: {
              eventId,
              role,
              field,
              ...(defaultValues ? { fieldName: defaultValues.name } : {}),
            },
          })
            .then(() => onClose())
            .then(() => refetch())
            .then(() => reset());
        })}
      >
        <DialogTitle>{defaultValues ? "Edit Field" : "Add Field"}</DialogTitle>
        <DialogContent>
          <Box sx={{ py: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="name"
                  rules={{
                    required: "Required",
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label="Field Name"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="type"
                  rules={{
                    required: "Required",
                  }}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={fieldTypes}
                      value={field.value}
                      onChange={(e, v) => {
                        field.onChange(v || "");
                        setOp(["select", "mcq", "radio","payment"].includes(v));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={ref}
                          label="Field Type"
                          fullWidth
                          required
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="label"
                  rules={{
                    maxLength: 1000,
                  }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      label="Field Label"
                      fullWidth
                      required
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="sx"
                  rules={{ required: "Required" }}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label="sx"
                      {...field}
                      fullWidth
                      required
                      multiline
                      minRows={1}
                      maxRows={6}
                      inputRef={ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  name="xs"
                  rules={{ required: "Required", max: 12, min: 1 }}
                  render={({
                    field: { ref, onChange, ...field },
                    fieldState,
                  }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      fullWidth
                      label="xs"
                      type="number"
                      onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  name="md"
                  rules={{ required: "Required", max: 12, min: 1 }}
                  render={({
                    field: { ref, onChange, ...field },
                    fieldState,
                  }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      fullWidth
                      label="md"
                      type="number"
                      onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  name="minLength"
                  rules={{ required: "Required", max: 3000, min: 0 }}
                  render={({
                    field: { ref, onChange, ...field },
                    fieldState,
                  }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      fullWidth
                      label="MinLength"
                      type="number"
                      onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Controller
                  control={control}
                  name="maxLength"
                  rules={{ required: "Required", max: 3000, min: 0 }}
                  render={({
                    field: { ref, onChange, ...field },
                    fieldState,
                  }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      fullWidth
                      label="MaxLength"
                      type="number"
                      onChange={(e) => onChange(e.currentTarget.valueAsNumber)}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="placeholder"
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      fullWidth
                      label="placeholder"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="defaultValue"
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      {...field}
                      inputRef={ref}
                      fullWidth
                      label="defaultValue"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {op && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="options"
                    rules={{ required: "Required" }}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label="Options"
                        value={field.value?.join("\n") || ""}
                        onChange={(e) =>
                          field.onChange(e.target.value?.split("\n") || [])
                        }
                        inputRef={ref}
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={10}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="pattern"
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label="RegExp Pattern"
                      {...field}
                      inputRef={ref}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="required"
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <FormControlLabel
                      label="Make it mandatory (*)"
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(e) =>
                            field.onChange(e.currentTarget.checked)
                          }
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="readonly"
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <FormControlLabel
                      label="Make it readonly"
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(e) =>
                            field.onChange(e.currentTarget.checked)
                          }
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {loading && <LinearProgress />}
          {error && (
            <Typography color="error">
              {JSON.stringify(error, null, 4)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={() => reset()}>Reset</Button>
          <LoadingButton
            type="submit"
            loading={formState.isSubmitting}
            variant="contained"
            color="warning"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const GET_FORM = gql`
  query ($eventId: String!, $role: ROLE!) {
    form(eventId: $eventId, role: $role) {
      fields {
        type
        name
        label
        sx
        xs
        md
        placeholder
        options
        defaultValue
        minLength
        maxLength
        pattern
        required
        readonly
      }
    }
  }
`;

const ADD_FIELD = gql`
  mutation ($eventId: String!, $role: ROLE!, $field: FieldInput!) {
    addField(eventId: $eventId, role: $role, field: $field) {
      id
    }
  }
`;
const DELETE_FIELD = gql`
  mutation ($eventId: String!, $role: ROLE!, $fieldName: String!) {
    removeField(eventId: $eventId, role: $role, fieldName: $fieldName) {
      id
    }
  }
`;
const UPDATE_FIELD = gql`
  mutation (
    $eventId: String!
    $role: ROLE!
    $field: FieldInput!
    $fieldName: String!
  ) {
    updateField(
      eventId: $eventId
      role: $role
      field: $field
      fieldName: $fieldName
    ) {
      id
    }
  }
`;

const IMPORT_FIELDS = gql`
  mutation ($eventId: String!, $role: ROLE!, $fields: [FieldInput]!) {
    importFields(eventId: $eventId, role: $role, fields: $fields) {
      id
    }
  }
`;

const UNIQUE_FIELDS = gql`
  query ($eventId: String!, $role: ROLE!) {
    keys: uniqueFieldNames(eventId: $eventId, role: $role) {
      index
      column
    }
  }
`;

const UPDATE_INDIVIDUAL_UNIQUE = gql`
  mutation ($eventId: String!, $role: ROLE!, $fieldNames: [String]!) {
    makeIndividualUniqueField(
      eventId: $eventId
      role: $role
      fieldNames: $fieldNames
    )
  }
`;
const UPDATE_COMBINED_UNIQUE = gql`
  mutation ($eventId: String!, $role: ROLE!, $fieldNames: [String]!) {
    makeCombinedUniqueField(
      eventId: $eventId
      role: $role
      fieldNames: $fieldNames
    )
  }
`;


export const ADD_FEATURE = gql`
mutation ($eventId: String!, $role: ROLE!,$field: String, $value: String!) {
  addMailVerificationfeature(eventId: $eventId, role: $role,field:$field, value: $value)
}
`;

export const GET_VERIFICATION_FEATURE = gql`
  query ($eventId: String!, $role: ROLE!) {
    mailVerificationFeature(eventId: $eventId, role: $role) {
      features
      action
      keywords
      keywordsAppliedColumns
      socialShare
      paymentDetails
    }
  }
`;


export const GET_SELECTED_FORM_FIELDS = gql`
  query ($eventId: String!) {
    getSelectedFormField(eventId: $eventId) 
  }
`;