import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  useMutation,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { TextField } from "@mui/material";
import Routes from "./Routes";
import { createUploadLink } from "apollo-upload-client";
import { API_URL } from "./constants";

const httpLink = createHttpLink({
  uri: API_URL,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth.admin.eventegration.com");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = createUploadLink({
  uri: API_URL,
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

const client = new ApolloClient({
  //@ts-ignore
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <div>
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </div>
  );
}

export default App;

function TestComponent() {
  const [login, { data, loading, error }] = useMutation(gql`
    mutation ($username: String!, $password: String!) {
      login(username: $username, password: $password)
    }
  `);
  return (
    <div>
      {data?.login}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const username = e.currentTarget.elements.namedItem("username").value;
          const password = e.currentTarget.elements.namedItem("password").value;
          login({ variables: { username, password } });
          return false;
        }}
      >
        <TextField label="Username" />
        <input width={100} name="password" />
        <button type="submit" disabled={loading}>
          Submit
        </button>
      </form>
      {error?.message}
    </div>
  );
}
