import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import AppLayout from "./Layouts/AppLayout";
import DashboardLayout from "./Layouts/DashboardLayout";
import EventLayout from "./Layouts/EventLayout";
import RoleLayout from "./Layouts/RoleLayout";
import CreateNewEventPage from "./Pages/CreateNewEventPage";
import EventPage from "./Pages/EventPage";
import EventStandsPage from "./Pages/EventStandsPage";
import FloorPlanPage from "./Pages/FloorPlanPage";
import LoginPage from "./Pages/LoginPage";
import LogoutPage from "./Pages/LogoutPage";
import MyEventsPage from "./Pages/MyEventsPage";
import RoleBadgePage from "./Pages/RoleBadgePage";
import RoleCertificatePage from "./Pages/RoleCertificatePage";
import RoleDataPage from "./Pages/RoleDataPage";
import RoleEmailPage from "./Pages/RoleEmailPage";
import RoleFormPage from "./Pages/RoleFormPage";
import RoleLinkPage from "./Pages/RoleLinkPage";
import RoleResponsePage from "./Pages/RoleResponsePage";

export default function IndexRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route
            path="events"
            element={
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route path=":eventId" element={<EventLayout />}>
              <Route path=":role" element={<RoleLayout />}>
                <Route path="data" element={<RoleDataPage />} />
                <Route path="form" element={<RoleFormPage />} />
                <Route path="link" element={<RoleLinkPage />} />
                <Route path="badge" element={<RoleBadgePage />} />
                <Route path="email" element={<RoleEmailPage />} />
                <Route path="response" element={<RoleResponsePage />} />
                <Route path="certificate" element={<RoleCertificatePage />} />
              </Route>
              <Route path="stands" element={<EventStandsPage />} />
              <Route path="floorplan" element={<FloorPlanPage />} />
              <Route index element={<EventPage />} />
            </Route>
            <Route path="new" element={<CreateNewEventPage />} />
            <Route index element={<MyEventsPage />} />
          </Route>
          <Route index element={<Navigate to="events" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  const [auth, setAuth] = React.useState(null);

  React.useEffect(() => {
    setAuth(!!localStorage.getItem("auth.admin.eventegration.com"));
  }, []);

  if (auth === null) {
    return (
      <div>
        <p>Please wait...</p>
      </div>
    );
  } else if (auth === false) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
}
