import { gql, useMutation, useQuery } from "@apollo/client";
import { Dashboard } from "@mui/icons-material";
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogContent,
  Link as MuiLink,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_URL, IMAGES_API_URL } from "../constants";
import { ROLES } from "../Roles";
import { Icon } from "@iconify/react";

export default function EventPage() {
  const { eventId } = useParams();
  const { data, loading, error, refetch } = useQuery(GET_EVENT, {
    variables: { eventId },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="error">{error?.message}</Typography>;
  }
  if (!data?.event) {
    return <Typography>No Event Found</Typography>;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h4" gutterBottom>
        {data.event.name}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        sx={{ background: "lightgrey", py: 3 }}
      >
        <Stack direction="column">
          <Typography variant="h5">Event Participants/Stands</Typography>
          <MuiLink component={Link} to="stands" underline="hover">
            Manage List
          </MuiLink>
        </Stack>
        <Stack direction="column">
          <Typography variant="h5">Event Floor Plan</Typography>
          <MuiLink component={Link} to="floorplan" underline="hover">
            Manage Floor Plan
          </MuiLink>
        </Stack>
        <Box height={30} />
      </Stack>
      <Typography variant="h5">Event Roles</Typography>
      <ActiveRolesTable event={data.event} refetch={refetch} />

      <Box height={30} />
      <Typography variant="h5">Event Images</Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell>Image</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Banner</TableCell>
              <TableCell>
                <EventBanner event={data.event} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Logo</TableCell>
              <TableCell>
                <EventLogo event={data.event} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Favicon</TableCell>
              <TableCell>
                <EventFavicon event={data.event} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

function EventBanner({ event }) {
  const [upload, { loading, error }] = useMutation(gql`
    mutation ($file: Upload!, $eventId: String!) {
      uploadEventBanner(eventId: $eventId, file: $file)
    }
  `);

  const [deleteBanner] = useMutation(gql`
    mutation ($eventId: String!) {
      deleteEventBanner(eventId: $eventId)
    }
  `);

  function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid) upload({ variables: { eventId: event.id, file } });
  }

  return (
    <Stack spacing={1}>
      <Box>
        <img
          src={IMAGES_API_URL + `banner/${event.id}.jpeg?t=${Date.now()}`}
          height={200}
        />
      </Box>

      <input type="file" accept="image/jpeg" required onChange={onChange} />
      <Box>
        <Button
          onClick={() => deleteBanner({ variables: { eventId: event.id } })}
          color="error"
        >
          Remove
        </Button>
      </Box>

      {loading && <LinearProgress />}
      {error && (
        <Typography color="error">{JSON.stringify(error, null, 0)}</Typography>
      )}
    </Stack>
  );
}

function EventLogo({ event }) {
  const [upload, { loading, error }] = useMutation(gql`
    mutation ($file: Upload!, $eventId: String!) {
      uploadEventLogo(eventId: $eventId, file: $file)
    }
  `);

  const [deleteLogo] = useMutation(gql`
    mutation ($eventId: String!) {
      deleteEventLogo(eventId: $eventId)
    }
  `);

  function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid) upload({ variables: { eventId: event.id, file } });
  }

  return (
    <Stack spacing={1}>
      <Box>
        <img
          src={IMAGES_API_URL + `logo/${event.id}.png?t=${Date.now()}`}
          height={80}
        />
      </Box>

      <input type="file" accept="image/png" required onChange={onChange} />
      <Box>
        <Button
          onClick={() => deleteLogo({ variables: { eventId: event.id } })}
          color="error"
        >
          Remove
        </Button>
      </Box>

      {loading && <LinearProgress />}
      {error && (
        <Typography color="error">{JSON.stringify(error, null, 0)}</Typography>
      )}
    </Stack>
  );
}

function EventFavicon({ event }) {
  const [upload, { loading, error }] = useMutation(gql`
    mutation ($file: Upload!, $eventId: String!) {
      uploadEventFavicon(eventId: $eventId, file: $file)
    }
  `);

  const [deleteFavicon] = useMutation(gql`
    mutation ($eventId: String!) {
      deleteEventFavicon(eventId: $eventId)
    }
  `);

  function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid) upload({ variables: { eventId: event.id, file } });
  }

  return (
    <Stack spacing={1}>
      <Box>
        <img
          src={
            IMAGES_API_URL +
            `favicon/${event.id}/favicon-32x32.png?t=${Date.now()}`
          }
          height={32}
        />
      </Box>

      <input type="file" accept=".zip" required onChange={onChange} />
      <Box>
        <Button
          onClick={() => deleteFavicon({ variables: { eventId: event.id } })}
          color="error"
        >
          Remove
        </Button>
      </Box>

      {loading && <LinearProgress />}
      {error && (
        <Typography color="error">{JSON.stringify(error, null, 0)}</Typography>
      )}
    </Stack>
  );
}

function ActiveRolesTable({ event, refetch }) {
  const activeRoles = event.roles?.split(",") || [];
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>#</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableHead>
        <TableBody>
          {ROLES.map((r, i) => {
            const live = activeRoles.includes(r);
            return (
              <TableRow key={r}>
                <TableCell>{i + 1}</TableCell>
                <TableCell sx={{ textTransform: "capitalize" }}>{r}</TableCell>
                <TableCell>
                  <ActivateRoleButton
                    live={live}
                    eventId={event.id}
                    role={r}
                    refetch={refetch}
                  />
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Button
                      onClick={() => navigate(`${r}/data`)}
                      startIcon={<Icon icon="bxs:data" />}
                      variant="contained"
                    >
                      Data
                    </Button>
                    <Button
                      onClick={() => navigate(`${r}/form`)}
                      startIcon={<Icon icon="ic:baseline-dynamic-form" />}
                      variant="contained"
                      disabled={!live}
                      color="info"
                    >
                      Form
                    </Button>
                    <Button
                      onClick={() => navigate(`${r}/link`)}
                      startIcon={<Icon icon="entypo:link" />}
                      variant="contained"
                      disabled={!live}
                      color="secondary"
                    >
                      Link
                    </Button>
                    <Button
                      onClick={() => navigate(`${r}/badge`)}
                      startIcon={<Icon icon="emojione-monotone:name-badge" />}
                      variant="contained"
                      disabled={!live}
                      color="error"
                    >
                      Badge
                    </Button>
                    <Button
                      onClick={() => navigate(`${r}/email`)}
                      startIcon={
                        <Icon icon="material-symbols:mark-email-unread" />
                      }
                      variant="contained"
                      disabled={!live}
                      color="success"
                    >
                      Email
                    </Button>
                    <Button
                      onClick={() => navigate(`${r}/response`)}
                      startIcon={<Icon icon="clarity:success-standard-solid" />}
                      variant="contained"
                      disabled={!live}
                      color="warning"
                    >
                      Response
                    </Button>
                    <Button
                      onClick={() => navigate(`${r}/certificate`)}
                      startIcon={<Icon icon="clarity:certificate-solid" />}
                      variant="contained"
                      disabled={!live}
                      color="error"
                    >
                      Certificate
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ActivateRoleButton({ live, eventId, role, refetch }) {
  const [toggleEventRole, { loading, error }] = useMutation(gql`
    mutation ($eventId: String!, $role: ROLE!, $live: Boolean!) {
      toggleEventRole(eventId: $eventId, role: $role, live: $live)
    }
  `);
  const handleChange = (e) => {
    // console.log(e.currentTarget.checked);
    toggleEventRole({
      variables: { eventId, role, live: e.currentTarget.checked },
    }).then(() => refetch());
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Dialog open>
        <DialogContent>{error.message}</DialogContent>
      </Dialog>
    );
  }

  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={live} onChange={handleChange} />}
        label="Live"
      />
    </Box>
  );
}

const GET_EVENT = gql`
  query ($eventId: String!) {
    event(eventId: $eventId) {
      id
      name
      roles
      created
    }
  }
`;
