import { gql, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../Roles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function CreateNewEventPage() {
  const [create, { loading, error }] = useMutation(CREATE_EVENT);
  const [addRole] = useMutation(ADD_ROLE);
  const navigate = useNavigate();
  // const handleSubmit = async (f) => {
  //   create({
  //     variables: {
  //       name: f.name,
  //     },
  //   })
  //     .then(async (res) => {
  //       const eventId = res.data.event.id;
  //       for (const role of f.roles) {
  //         await addRole({ variables: { eventId, role } });
  //       }
  //     })
  //     .then(() => {
  //       navigate("/events");
  //     });
  // };
  const handleSubmit = async (f) => {
    create({
      variables: {
        name: f.name,
        startAt: f.startAt,
        endAt: f.endAt,
        startTime: f.startTime,
        location: f.location,
        endTime: f.endTime,
      },
    })
      .then(async (res) => {
        const eventId = res.data.createEvent.id;
        for (const role of f.roles) {
          await addRole({ variables: { eventId, role } });
        }
      })
      .then(() => {
        navigate("/events");
      });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Create New Event
      </Typography>
      <Paper
        sx={{ p: 2, maxWidth: (t) => t.breakpoints.values.md }}
        variant="outlined"
      >
        <EventForm onSubmit={handleSubmit} />
        {loading && <LinearProgress />}
        {!!error && (
          <motion.div
            animate={{
              x: [30, -30, 15, -15, 8, 0],
            }}
            transition={{ duration: 0.4 }}
          >
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error.message}
            </Typography>
          </motion.div>
        )}
      </Paper>
    </div>
  );
}
const validateDates = (startDate, endDate) => {
  if (!startDate || !endDate) return true;
  return new Date(endDate) > new Date(startDate);
};

const validateTimes = (startTime, endTime) => {
  if (!startTime || !endTime) return true;
  
  // Check if startTime and endTime are Date objects
  if (!(startTime instanceof Date) || !(endTime instanceof Date)) {
    console.error('Invalid time format. Expected Date objects.');
    return false;
  }

  const startHour = startTime.getHours();
  const startMinute = startTime.getMinutes();
  const endHour = endTime.getHours();
  const endMinute = endTime.getMinutes();


  if (endHour > startHour) return true;
  if (endHour === startHour) return endMinute > startMinute;
  return false;
};

function EventForm({ onSubmit }) {
  const { control, formState, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      name: "",
      roles: ["visitor", "exhibitor"],
      startAt: null,
      endAt: null,
      startTime: null,
      location: "",
      endTime: null,
    },
    mode: "onChange",
  });

  const startAt = watch("startAt");
  const endAt = watch("endAt");
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  const onSubmitWithValidation = (data) => {
    if (!validateDates(data.startAt, data.endAt)) {
      alert("End date must be after start date");
      return;
    }
    if (!validateTimes(data.startTime, data.endTime)) {
      alert("End time must be after start time");
      return;
    }
    onSubmit(data);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form noValidate onSubmit={handleSubmit(onSubmitWithValidation)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              rules={{ required: "Event name is required" }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label="Event Name"
                  fullWidth
                  size="large"
                  variant="standard"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="startAt"
              rules={{ required: "Start date is required" }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="Start Date"
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="endAt"
              rules={{ 
                required: "End date is required",
                validate: (value) => validateDates(startAt, value) || "End date must be after start date"
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="End Date"
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="startTime"
              rules={{ required: "Start time is required" }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TimePicker
                  {...field}
                  label="Start Time"
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="endTime"
              rules={{ 
                required: "End time is required",
                validate: (value) => validateTimes(startTime, value) || "End time must be after start time"
              }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TimePicker
                  {...field}
                  label="End Time"
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="location"
              rules={{ required: "Location is required" }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  label="Location"
                  fullWidth
                  variant="standard"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="roles"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    <FormLabel component="legend">Active Roles</FormLabel>
                    {ROLES.map((role) => (
                      <FormControlLabel
                        key={`role-${role}`}
                        control={
                          <Switch
                            checked={field.value.indexOf(role) > -1}
                            name={role}
                            onChange={(ev) => {
                              if (field.value.indexOf(role) === -1) {
                                setValue("roles", [...field.value, role]);
                              } else {
                                setValue(
                                  "roles",
                                  field.value.filter((r) => r !== role)
                                );
                              }
                            }}
                          />
                        }
                        label={role}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Create Event
            </Button>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
}

// function EventForm({ onSubmit }) {
//   const { control, formState, handleSubmit, setValue } = useForm({
//     defaultValues: {
//       name: "",
//       roles: ["visitor", "exhibitor"],
//       startAt: null,
//       endAt: null,
//       startTime: null,
//       location: "",
//       endTime: null,
//     },
//   });

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <form noValidate onSubmit={handleSubmit(onSubmit)}>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <Controller
//               control={control}
//               name="name"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <TextField
//                   {...field}
//                   inputRef={ref}
//                   label="Event Name"
//                   fullWidth
//                   size="large"
//                   variant="standard"
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Controller
//               control={control}
//               name="startAt"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <DatePicker
//                   {...field}
//                   label="Start Date"
//                   renderInput={(params) => <TextField {...params} fullWidth />}
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Controller
//               control={control}
//               name="endAt"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <DatePicker
//                   {...field}
//                   label="End Date"
//                   renderInput={(params) => <TextField {...params} fullWidth />}
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Controller
//               control={control}
//               name="startTime"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <TimePicker
//                   {...field}
//                   label="Start Time"
//                   renderInput={(params) => <TextField {...params} fullWidth />}
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Controller
//               control={control}
//               name="endTime"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <TimePicker
//                   {...field}
//                   label="End Time"
//                   renderInput={(params) => <TextField {...params} fullWidth />}
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Controller
//               control={control}
//               name="location"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <TextField
//                   {...field}
//                   inputRef={ref}
//                   label="Location"
//                   fullWidth
//                   size="large"
//                   variant="standard"
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Controller
//               control={control}
//               name="roles"
//               render={({ field: { ref, ...field }, fieldState: { error } }) => (
//                 <FormControl component="fieldset" variant="standard">
//                   <FormGroup>
//                     <FormLabel component="legend">Active Roles</FormLabel>
//                     {ROLES.map((role) => (
//                       <FormControlLabel
//                         key={role}
//                         control={
//                           <Switch
//                             checked={field.value.indexOf(role) > -1}
//                             name={role}
//                             onChange={(ev) => {
//                               if (field.value.indexOf(role) === -1) {
//                                 setValue("roles", [...field.value, role]);
//                               } else {
//                                 setValue(
//                                   "roles",
//                                   field.value.filter((r) => r !== role)
//                                 );
//                               }
//                             }}
//                           />
//                         }
//                         label={role}
//                       />
//                     ))}
//                   </FormGroup>
//                 </FormControl>
//               )}
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button type="submit" variant="contained" color="primary">
//               Create Event
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//     </LocalizationProvider>
//   );
// }

// function EventForm({ onSubmit }) {
//   const { control, formState, handleSubmit, setValue } = useForm({
//     defaultValues: {
//       name: "",
//       roles: ["visitor", "exhibitor"],
//     },
//   });
//   return (
//     <form noValidate onSubmit={handleSubmit(onSubmit)}>
//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Controller
//             control={control}
//             name="name"
//             render={({ field: { ref, ...field }, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 inputRef={ref}
//                 label="Event Name"
//                 fullWidth
//                 size="large"
//                 variant="standard"
//               />
//             )}
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <Controller
//             control={control}
//             name="roles"
//             render={({ field: { ref, ...field }, fieldState: { error } }) => (
//               <FormControl component="fieldset" variant="standard">
//                 <FormGroup>
//                   <FormLabel component="legend">Active Roles</FormLabel>
//                   {ROLES.map((role) => (
//                     <FormControlLabel
//                       key={role}
//                       control={
//                         <Switch
//                           checked={field.value.indexOf(role) > -1}
//                           name={role}
//                           onChange={(ev) => {
//                             if (field.value.indexOf(role) === -1) {
//                               setValue("roles", [...field.value, role]);
//                             } else {
//                               setValue(
//                                 "roles",
//                                 field.value.filter((r) => r !== role)
//                               );
//                             }
//                           }}
//                         />
//                       }
//                       label={role}
//                     />
//                   ))}
//                 </FormGroup>
//               </FormControl>
//             )}
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <LoadingButton
//             fullWidth
//             size="large"
//             variant="contained"
//             loading={formState.isSubmitting}
//             type="submit"
//           >
//             Create
//           </LoadingButton>
//         </Grid>
//       </Grid>
//     </form>
//   );
// }
const CREATE_EVENT = gql`
  mutation (
    $name: String!
    $startAt: String
    $endAt: String
    $startTime: String
    $location: String
    $endTime: String
  ) {
    createEvent(
      name: $name
      startAt: $startAt
      endAt: $endAt
      startTime: $startTime
      location: $location
      endTime: $endTime
    ) {
      id
      name
      startAt
      endAt
      startTime
      location
      endTime
      created
    }
  }
`;

const ADD_ROLE = gql`
  mutation ($eventId: String!, $role: ROLE!) {
    addRoleTable(eventId: $eventId, role: $role)
  }
`;

// const CREATE_EVENT = gql`
//   mutation ($name: String!) {
//     event: createEvent(name: $name) {
//       id
//       name
//       created
//     }
//   }
// `;

// const ADD_ROLE = gql`
//   mutation ($eventId: String!, $role: ROLE!) {
//     addRoleTable(eventId: $eventId, role: $role)
//   }
// `;
