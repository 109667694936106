import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { csvToArray } from "../constants";

export default function FloorPlanPage() {
  const { eventId } = useParams();

  // const { data, refetch } = useQuery(
  //   gql`
  //     query ($eventId: String) {
  //       getFloorPlan(eventId: $eventId){
  //         variable:{
  //           eventId:eventId
  //         }
  //       }
  //     }
  //   `
  // );
  const { data, loading, error, refetch } = useQuery(GET_FLOOR_PLAN, {
    variables: { eventId },
  });

  const [upload] = useMutation(
    gql`
      mutation ($file: Upload!, $eventId: String) {
        addFloorPlan(file: $file, eventId: $eventId)
      }
    `,
    { onCompleted: refetch }
  );
  const [deleteFloorPlan] = useMutation(
    gql`
      mutation ($filename: String!, $eventId: String) {
        deleteFloorPlan(filename: $filename, eventId: $eventId)
      }
    `,
    { onCompleted: refetch }
  );

  function onChange({
    target: {
      validity,
      files: [file],
    },
  }) {
    if (validity.valid) upload({ variables: { file, eventId } });
  }

  return (
    <React.Fragment>
      <Typography
        variant="h4"
        sx={{ textTransform: "capitalize" }}
        gutterBottom
      >
        Floor Plan
      </Typography>
      <Grid container md={12}>
        {/* <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item md={6}></Grid>
        </form> */}
        <TableContainer>
          <Table>
            {data?.getFloorPlan?.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{i + 1}.</TableCell>
                <TableCell>
                  <img src={item} alt="floorPlan" height={180} />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      deleteFloorPlan({
                        variables: { filename: item, eventId },
                      })
                    }
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Upload New</TableCell>
              <TableCell>
                <input
                  type="file"
                  accept="image/jpeg"
                  required
                  onChange={onChange}
                />
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Grid>
      {/* <FloorPlanList eventId={eventId} /> */}
    </React.Fragment>
  );
}

// function FloorPlanList({ eventId }) {
//   const { data, loading, refetch } = useQuery(GET_FLOOR_PLAN, {
//     variables: { eventId },
//   });
//   console.log(data);
// const [selectedIds, setSelectedIds] = React.useState([]);
// const [updateStand] = useMutation(UPDATE_STAND);

//   return <React.Fragment></React.Fragment>;
// }

// function DeleteStand({ eventId, onDelete, ids, ...props }) {
//   const [deleteStands] = useMutation(DELETE_STANDS);

//   const handleDelete = () => {
//     if (!window.confirm("Are you sure you want to delete")) {
//       return;
//     }
//     deleteStands({ variables: { eventId, ids } })
//       .then(onDelete)
//       .catch((e) => window.alert(e.message));
//   };

//   if (!ids?.length) {
//     return null;
//   }

//   return <Button onClick={handleDelete} {...props} />;
// }

// function AddNewStand({ eventId, onAdd, ...props }) {
//   const [addStand] = useMutation(ADD_STAND);
//   const [open, setOpen] = React.useState(false);
//   const { control, formState, handleSubmit } = useForm({
//     defaultValues: {
//       company: "",
//       email: "",
//     },
//   });
//   return (
//     <React.Fragment>
//       <Dialog
//         open={open}
//         onClose={() => setOpen(false)}
//         maxWidth="sm"
//         fullWidth
//       >
//         <DialogTitle>Add New Stand</DialogTitle>
//         <DialogContent>
//           <form
//             onSubmit={handleSubmit(async (inp) => {
//               addStand({
//                 variables: {
//                   eventId,
//                   inp,
//                 },
//               })
//                 .then(onAdd)
//                 .catch((r) => window.alert(r.message))
//                 .finally(() => setOpen(false));
//             })}
//           >
//             <Stack p={1} spacing={1}>
//               <Controller
//                 control={control}
//                 name="company"
//                 rules={{ required: "Required" }}
//                 render={({
//                   field: { ref, ...field },
//                   fieldState: { error },
//                 }) => (
//                   <TextField
//                     inputRef={ref}
//                     {...field}
//                     error={!!error}
//                     helperText={error?.message}
//                     label="Company"
//                     fullWidth
//                   />
//                 )}
//               />
//               <Controller
//                 control={control}
//                 name="email"
//                 rules={{ required: "Required" }}
//                 render={({
//                   field: { ref, ...field },
//                   fieldState: { error },
//                 }) => (
//                   <TextField
//                     inputRef={ref}
//                     {...field}
//                     error={!!error}
//                     helperText={error?.message}
//                     label="Email"
//                     fullWidth
//                   />
//                 )}
//               />
//               <LoadingButton
//                 loading={formState.isSubmitting}
//                 variant="contained"
//                 type="submit"
//               >
//                 Create
//               </LoadingButton>
//               <Button onClick={() => setOpen(false)}>Cancel</Button>
//             </Stack>
//           </form>
//         </DialogContent>
//       </Dialog>
//       <Button {...props} onClick={() => setOpen(true)} />
//     </React.Fragment>
//   );
// }

const GET_FLOOR_PLAN = gql`
  query ($eventId: String!) {
    getFloorPlan(eventId: $eventId)
  }
`;

// const ADD_FLOOR_PLAN = gql`
//   mutation ($eventId: String!, $file: Upload) {
//     result: addFloorPlan(eventId: $eventId, file: $file)
//   }
// `;
// const UPLOAD_STANDS = gql`
//   mutation ($eventId: String!, $stands: [StandInput]) {
//     result: uploadFloorPlan(eventId: $eventId, stands: $stands)
//   }
// `;
// const UPDATE_STAND = gql`
//   mutation ($eventId: String!, $id: String!, $field: String!, $value: String!) {
//     result: updateStand(
//       eventId: $eventId
//       id: $id
//       field: $field
//       value: $value
//     )
//   }
// `;

// const DELETE_FLOOR_PLAN = gql`
//   mutation ($eventId: String!, $id: [String]!) {
//     result: deleteFloorPlan(eventId: $eventId, id: $id)
//   }
// `;
