import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputLabel, Select } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

export default function AddPaymentMethod({ open, handleClose, eventId, role, refresh }) {
  const [addPaymentOption, { loading, error }] = useMutation(ADD_PAYMENT_OPTION);

  const onSubmit = async (formData, event) => {
    try {
      event.preventDefault();
      const inp = JSON.stringify(formData);
      await addPaymentOption({
        variables: {
          eventId,
          role,
          inp,
        },
      });
      setTimeout(() => {
        handleClose();
        refresh()
      }, 500); 
    } catch (error) {
      console.error('Error submitting form:', error?.message);
    }
  };
  

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        // PaperProps={{
        //   component: 'form',
        //   onSubmit: (event) => {
        //     event.preventDefault();
        //     handleSubmit(onSubmit)(event);
        //   },
        // }}
      >
        <DialogTitle>Add Payment Method</DialogTitle>
        <DialogContent>
          <EventForm onSubmit={onSubmit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function EventForm({ onSubmit, editValues }) {
  const { control, formState, handleSubmit } = useForm({
    defaultValues: editValues
      ? editValues
      : {
        paymentMethod: 'paypal',
        secretKey: '',
        publicKey: '',
        apiKey: '',
        apiurl: '',
        currency: 'USD',
      },
  });

  const paymentOptions = ['paypal','paymob','stripe', 'checkout', 'razorpay', 'paytm'];
  const currencyOptions = ['INR', 'USD', 'OMR', 'SAR', 'AED'];

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="paymentMethod"
            render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="payment-method">Payment Method</InputLabel>
                <Select
                  {...field}
                  inputRef={ref}
                  native
                  label="Payment Method"
                  onChange={onChange}
                >
                  <option value="" disabled>
                    Select Payment Method
                  </option>
                  {paymentOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="secretKey"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label="Secret Key"
                fullWidth
                size="large"
                variant="standard"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="publicKey"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label="Public Key"
                fullWidth
                size="large"
                variant="standard"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="apiKey"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label="API Key/Client Id"
                fullWidth
                size="large"
                variant="standard"

              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="apiurl"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label="API URL"
                fullWidth
                size="large"
                variant="standard"

              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="currency"
            render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="currency">Select Currency</InputLabel>
                <Select
                  {...field}
                  inputRef={ref}
                  native
                  label="Select Currency"
                  onChange={onChange}
                >
                  <option value="" disabled>
                    Select Currency
                  </option>
                  {currencyOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={formState.isSubmitting}
            type="submit"
          >
            {editValues ? 'Update' : 'Save'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

const ADD_PAYMENT_OPTION = gql`
  mutation ($eventId: String!, $role: String!, $inp: String!) {
    addPaymentOption(eventId: $eventId, role: $role, inp: $inp)
  }
  `