// IsVerificationFeatureRequired.js
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GET_FORM_FIELDS } from '../Pages/EventStandsPage';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_FEATURE, GET_VERIFICATION_FEATURE } from '../Pages/RoleFormPage';
import { Autocomplete, Button, Chip, Paper, Stack, TextField } from '@mui/material';
import PaymentSetting from './PaymentSetting';

export default function IsVerificationFeatureRequired({ eventId, role, selectedFeatures }) {
  console.log(selectedFeatures);
  const [tags, setTags] = React.useState([]);
  const [keywords, setKeywords] = React.useState([]);
  const [action, setAction] = React.useState([]);
  const [socialShare, setSocialShare] = React.useState([]);
  const [keywordApplyColum, setKeywordApplyColum] = React.useState([]);
  const [selectedPaymentOptions, setselectedPaymentOptions] = React.useState([]);
  const [addMailVerificationFeature] = useMutation(ADD_FEATURE);
  const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
    variables: { eventId, role },
  });


  React.useEffect(() => {
    if (featureData && featureData.mailVerificationFeature) {
      let parsedData = [];
      let parsedKeywords = [];
      let parsedAction = [];
      let parsedkeywordsAppliedColumns = [];
      let parsedSocialShare = [];
      let parsedPaymentOptions = [];
      try {
        parsedData = JSON.parse(featureData.mailVerificationFeature.features);
        parsedKeywords = JSON.parse(featureData.mailVerificationFeature.keywords);
        parsedAction = JSON.parse(featureData.mailVerificationFeature.action);
        parsedkeywordsAppliedColumns = JSON.parse(featureData.mailVerificationFeature.keywordsAppliedColumns);
        parsedSocialShare = JSON.parse(featureData.mailVerificationFeature.socialShare);
        parsedPaymentOptions = JSON.parse(featureData.mailVerificationFeature.paymentDetails);
      } catch (err) {
        console.error("Error parsing the data:", err);
      }
      if (Array.isArray(parsedData)) {
        setTags(parsedData);
        setKeywords(parsedKeywords);
        setAction(parsedAction);
        setKeywordApplyColum(parsedkeywordsAppliedColumns);
        setSocialShare(parsedSocialShare);
        setselectedPaymentOptions(parsedPaymentOptions);
      }
    }
  }, [featureData]);

  const actions = ["accept", "reject","pending"];
  const paymentOptions = ["paypal", "stripe", "checkout", "razorpay", "paytm"];
  const availableActions = action?.includes('accept') ? ['reject', 'pending'] : action?.includes('reject') ? ['accept', 'pending'] : action?.includes('pending') ? ['accept', 'reject'] : actions;

  const { data: columnData, loading: columnLoading } = useQuery(GET_FORM_FIELDS, {
    variables: { eventId },
  });

  const allColumnList = columnData?.getAllFormField;
  console.log(allColumnList);

  const handleSocialShareChange = (event, newValue) => {
    const field = "socialShare"
    setSocialShare(newValue);
    addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  };
  // const handleTagsChange = (event, newValue) => {
  //   const field = "features"
  //   setTags(newValue);
  //   addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  // };
  const handleKeywordsChange = (event, newValue) => {
    const field = "keywords"
    setKeywords(newValue);
    addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  };
  const handleActionChange = (event, newValue) => {
    const field = "action"
    setAction(newValue);
    addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  };
  const handlePaymentChange = (event, newValue) => {
    setselectedPaymentOptions(newValue);
  };
  const handleSavePaymentChange = () => {
    const field = "paymentDetails"
    addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(selectedPaymentOptions) } });
  };
  const keywordsAppliedColumns = (event, newValue) => {
    const field = "keywordsAppliedColumns"
    setKeywordApplyColum(newValue);
    addMailVerificationFeature({ variables: { eventId, role, field, value: JSON.stringify(newValue) } });
  };

  if (featureLoading) return <span>Loading...</span>;
  return (
    <Paper variant="outlined" sx={{ p: 2, m: 2 }}>
      <Grid container spacing={2} m={2} sx={{ width: '100%' }}>
        {selectedFeatures && selectedFeatures.length > 0 && (
          <>
            {/* Render components based on selectedFeatures */}
            {selectedFeatures.includes('emailVerification') && (
              <>
                {/* Render components specific to emailVerification */}
                <Grid item md={12} >
                  <Typography variant="h4">Email Verification Feature</Typography>
                  <Typography variant="body2" color={"error"}>For this feature make one form field which name is status sx= "display":"none"</Typography>
                  <Paper variant="outlined" sx={{ p: 2, m: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          freeSolo
                          defaultValue={keywords}
                          options={[]}
                          value={keywords}
                          onChange={handleKeywordsChange}
                          renderTags={(value, getTagProps) =>
                            Array.isArray(value) ? value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                color="success"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            )) : null
                          }

                          renderInput={(params) => (
                            <Stack direction="column">
                              <TextField
                                {...params}
                                label="Add Keywords"
                                variant="outlined"
                                fullWidth
                              />
                            </Stack>
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple={false}
                          freeSolo
                          defaultValue={action}
                          options={availableActions}
                          value={action}
                          onChange={handleActionChange}
                          renderTags={(value, getTagProps) =>
                            Array.isArray(value) ? value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                color="success"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            )) : null
                          }
                          renderInput={(params) => (
                            <Stack direction="column">
                              <TextField
                                {...params}
                                label="If keyword match then which action you want to perform"
                                variant="outlined"
                                fullWidth
                              />
                            </Stack>
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          multiple
                          freeSolo
                          defaultValue={keywordApplyColum}
                          options={allColumnList}
                          value={keywordApplyColum}
                          onChange={keywordsAppliedColumns}
                          renderTags={(value, getTagProps) =>
                            Array.isArray(value) ? value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                color="success"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            )) : null
                          }

                          renderInput={(params) => (
                            <Stack direction="column">
                              <TextField
                                {...params}
                                label="Select column where you want to apply keywords"
                                variant="outlined"
                                fullWidth
                              />

                            </Stack>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}

            {selectedFeatures.includes('ShareButton') && (
              <>
                {/* Render components specific to DownloadBadge */}
                <Grid item md={12}>
                  <Typography variant="h4">Share Button Content</Typography>
                  <Paper variant="outlined" sx={{ p: 2, m: 2 }}>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        multiple={false}
                        freeSolo
                        defaultValue={socialShare}
                        options={[]}
                        value={socialShare}
                        onChange={handleSocialShareChange}
                        renderTags={(value, getTagProps) =>
                          Array.isArray(value) ? value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              color="success"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          )) : null
                        }
                        renderInput={(params) => (
                          <Stack direction="column">
                            <TextField
                              {...params}
                              label="SocialShare Content"
                              variant="outlined"
                              fullWidth
                            />
                          </Stack>
                        )}
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}
            {selectedFeatures.includes('PaymentEnable') && (
              <>
                <Grid item md={12}>
                  <Typography variant="h4">Payment Enable</Typography>
                  <Paper variant="outlined" sx={{ p: 2, m: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <PaymentSetting eventId={eventId} role={role} />
                  </Paper>
                </Grid>
              </>
            )}
            {selectedFeatures.includes('DownloadBadge') && (
              <>
                {/* Render components specific to DownloadBadge */}
                <Grid item md={12}>
                  <Typography variant="h4">Download Badge Enable</Typography>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Paper>
  );
}
